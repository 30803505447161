import {getOrderDetails, sendUpsShipment} from "../../api/orders";
import {useLoaderData} from "react-router-dom";
import React, {useState} from "react";
import Card from "../../components/UI/Card";
import Button from "../../components/UI/Button";
import { OrderStatusString} from "../../util/helper";
import {confirmAlert} from "react-confirm-alert";
import {toast} from "react-toastify";
import {useMutation} from "@tanstack/react-query";

import {setValidationMessage} from "../../util/validationMessage";
import {useForm} from "react-hook-form";
import Input from "../../components/UI/form/Input";
import Select from "../../components/UI/form/Select";
import {getStates} from "../../api/commonRequest";


export async function orderDetailsLoad({params}){
  const [orderDetails, states] = await Promise.all([
    getOrderDetails(params.id),
    getStates()
  ]);
  return {orderDetails, states};
}

const UPSShipping = () => {
  const [order, setOrder] = useState(null);
  const [label, setLabel] = useState(null);
  const [states, setStates] = useState([]);
  const loaderData = useLoaderData();

  const [toastId, setToastId] = useState(null);
  const {setValue,  control, register, setError, getValues,  watch, handleSubmit, formState: { errors }} = useForm({
    defaultValues:{
      width: 1,
      height: 1,
      weight: 1,
      shipping_country: "",
      length: 1
    }
  })

  if(loaderData && !order) {
    if(loaderData.states) {
      setStates(loaderData.states.filter((item) => item.country_id === 1))
    }

    let orderData = loaderData.orderDetails?.data

    if(orderData && orderData.shipment){
      setLabel(orderData.shipment)
    }
    setValue('shipping_name', orderData.shipping_name)
    setValue('shipping_mobile', orderData.shipping_mobile)
    setValue('shipping_address', orderData.shipping_address)
    setValue('shipping_city', orderData.shipping_city)
    setValue('shipping_country', orderData.shipping_country)
    setValue('shipping_postal_code', orderData.shipping_postal_code)
    setOrder(orderData);
  }
  const [upsErrors, setUpsErrors] = useState([]);


  const sendShipment = (data) => {
    if(order.status !== 5){
      toast.error("Order is not complete yet!")
    } else if(order.payment_status !== "success"){
      toast.error("Order payment not completed!")
    } else {
      confirmAlert({
        title: 'Shipment?',
        message: 'Are you sure to do this?',
        buttons: [
          {
            label: 'Yes',
            onClick: () => {
              mutate(data)
            },
          },
          {
            label: 'No',
            onClick: () => {
              return;
            },
          }
        ]
      });

    }
  }



  const {isPending, mutate} = useMutation({
    mutationFn:  (data)=>sendUpsShipment(order.id, data),
    onMutate: () => {
      setToastId(toast.loading("Please wait..."))
    },
    onSuccess: (res) => {
      if(!res.success && res.errors.length){
        setUpsErrors(res.errors)
        toast.update(toastId, { render: 'UPS Policy errors', type: "error", isLoading: false, autoClose: true });
      } else {
        setLabel(res.data)
        toast.update(toastId, { render: `Ups Shipment label has been generated'}`, type: "success", isLoading: false, autoClose: true });
      }

    },
    onError: (error) => {
      let errorMsg = "Something happened!";
      if (error && error.message && error.message.errors) {
        setValidationMessage(error.message.errors, setError);
        errorMsg = error.message.message;
      }
      toast.update(toastId, { render: errorMsg, type: "error", isLoading: false, autoClose: true });
    }

  });

  const submitHandler = (data) => {
    sendShipment(data);
  };

  const removeError = (index) => {
    if(upsErrors.length > 0) {
      setUpsErrors((prevList) => prevList.filter((_, i) => i !== index));
    }
  }

  return (
    <>{order &&
      <form onSubmit={handleSubmit(submitHandler)}>
        <div className="row">
          <div className="col_12">
            <h1 className="common_title">{'Order Number: ' + order.order_number}</h1>
          </div>
        </div>
        <div className="row">
          <div className="col_6">
            <Card cardBodyCls={'pb_5'}>
              <h1 className="common_sub_title d_flex_btwn mt_0 mb_5">
                <span>Order Summary</span>

              </h1>
              <div className="checkout_summery ">
                <table className="table border_none">
                  <tbody>
                  <tr>
                    <td className="  text_left width_100p fw_600">Order Status</td>
                    <td className={"pb_0 pt_0"}>{OrderStatusString(order.status)}</td>
                  </tr>
                  <tr>
                    <td className="  text_left width_100p fw_600">Payment Status</td>
                    <td className={"pb_0 pt_0"}>{order.payment_status}</td>
                  </tr>

                  <tr>
                    <td className="  text_left width_100p fw_600">Sub-Total</td>
                    <td className={"pb_0 pt_0"}>${order.sub_total}</td>
                  </tr>
                  <tr>
                    <td className="  text_left width_100p fw_600">Total</td>
                    <td className={"pb_0 pt_0"}>${order.total}</td>
                  </tr>
                  <tr>
                    <td className="  text_left width_100p fw_600">Discount</td>
                    <td className={"pb_0 pt_0"}>{order.discount}</td>
                  </tr>
                  <tr>
                    <td className="  text_left width_100p fw_600">Date</td>
                    <td className={"pb_0 pt_0"}>{order.created_at}</td>
                  </tr>
                  </tbody>
                </table>
              </div>

            </Card>
          </div>
          <div className="col_6">
            <Card >
              <h1 className="common_sub_title d_flex_btwn mt_0 mb_5">
                <span>Shipping To Address</span>

              </h1>
              <div className="checkout_summery ">
                <table className="table border_none">
                  <tbody>
                  <tr>
                    <td className={"pb_0 pt_0"}>
                      <div className={'p_relative d_flex_inline'}>
                        <Input label="Name"
                               name="shipping_name"
                               classes={"mb_5"}
                               inline={true}
                               errors={errors}
                               validationRules={{required: true, type: 'string'}}
                               register={register}/>
                      </div>
                    </td>
                    <td className={"pb_0 pt_0"}>
                      <div className={'p_relative d_flex_inline'}>
                        <Input label="Phone"
                               name="shipping_mobile"
                               inline={true}
                               classes={"mb_5"}
                               errors={errors}
                               validationRules={{required: true, type: 'string'}}
                               register={register}/>
                      </div>
                    </td>
                  </tr>

                  <tr>
                    <td className={"pb_0 pt_0"}>
                      <div className={'p_relative d_flex_inline'}>
                        <Input label="Address"
                               name="shipping_address"
                               inline={true}
                               classes={"mb_5"}
                               errors={errors}
                               validationRules={{required: true, type: 'string'}}
                               register={register}/>
                      </div>
                    </td>
                    <td className={"pb_0 pt_0"}>
                      <div className={'p_relative d_flex_inline'}>
                        <Input label="City"
                               name="shipping_city"
                               inline={true}
                               classes={"mb_5"}
                               errors={errors}
                               validationRules={{required: true, type: 'string'}}
                               register={register}/>
                      </div>
                    </td>
                  </tr>

                  <tr>
                    <td className={"pb_0 pt_0"}>
                      <div className={'p_relative d_flex_inline'}>
                        <Input label="Postal Code"
                               name="shipping_postal_code"
                               inline={true}
                               classes={"mb_5"}
                               errors={errors}
                               validationRules={{required: true, type: 'string'}}
                               register={register}/>
                      </div>
                    </td>
                  </tr>
                  <tr>
                    <td className={"pb_0 pt_0"}>
                      <div className={'p_relative d_flex_inline'}>
                        <Select
                          label={"State"}
                          inline={true}
                          name={`shipping_country`}
                          options={states}
                          errors={errors}
                          optionValue={'code'}
                          register={register}
                          className={'mb_5'}
                        />
                      </div>
                    </td>
                  </tr>

                  </tbody>
                </table>
              </div>

            </Card>
          </div>
          <div className="col_6">
            <Card>
              <h1 className="common_sub_title d_flex_btwn mt_0 mb_5">
                <span>Packaging</span>
              </h1>
              <div className="checkout_summery ">
                <table className="table border_none">
                  <tbody>
                  <tr>
                    <td className={"pb_0 pt_0"}>
                      <div className={'p_relative d_flex_inline'}>
                        <Input label="Length"
                               name="length"
                               inline={true}
                               errors={errors}
                               validationRules={{required: true, type: 'number', min: 1}}
                               register={register}/>
                        <label className={"ml_10"}>Inches</label>
                      </div>
                    </td>
                  </tr>
                  <tr>
                    <td className={"pb_0 pt_0"}>
                      <div className={'p_relative d_flex_inline'}>
                        <Input label="Width"
                               name="width"
                               inline={true}
                               errors={errors}
                               validationRules={{required: true, type: 'number', min: 1}}
                               register={register}/>
                        <label className={"ml_10"}>Inches</label>
                      </div>
                    </td>
                  </tr>
                  <tr>
                    <td className={"pb_0 pt_0"}>
                      <div className={'p_relative d_flex_inline'}>
                        <Input label="Height"
                               name="height"
                               inline={true}
                               errors={errors}
                               validationRules={{required: true, type: 'number', min: 1}}
                               register={register}/>
                        <label className={"ml_10"}>Inches</label>
                      </div>
                    </td>
                  </tr>
                  <tr>
                    <td className={"pb_0 pt_0"}>
                      <div className={'p_relative d_flex_inline'}>
                        <Input label="Weight"
                               name="weight"
                               inline={true}
                               errors={errors}
                               validationRules={{required: true, type: 'number', min: 1}}
                               register={register}/>
                        <label className={"ml_10"}>Pounds</label>
                      </div>
                    </td>
                  </tr>
                  </tbody>
                </table>
              </div>

            </Card>
          </div>
          {label &&
            <div className="col_6">
              <Card>
                <div className="row">
                  <div className="col_6">
                    <h1 className="common_sub_title d_flex_btwn mt_0 mb_5">
                      <span>UPS Info</span>
                    </h1>
                    <div className="checkout_summery ">
                      <table className="table border_none">
                        <tbody>
                        <tr>
                          <td className={"  pt_0 text_left width_260p fw_600"}>Charge</td>
                          <td className={" pt_0"}>${label.charge}</td>
                        </tr>
                        <tr>
                          <td className={" pt_0 text_left width_260p fw_600"}>Shipment Identification Number</td>
                          <td className={" pt_0"}>{label.shipment_identification_number}</td>
                        </tr>

                        <tr>
                          <td className={" pt_0 text_left width_260p fw_600"}>Tracking Number</td>
                          <td className={" pt_0"}>{label.tracking_number}</td>
                        </tr>
                        <tr>
                          <td className={" pt_0 text_left width_260p fw_600"}>Date</td>
                          <td className={" pt_0"}>{label.created_at}</td>
                        </tr>

                        </tbody>
                      </table>
                    </div>
                  </div>
                  <div className="col_6">
                    <div className="d_flex_end">
                      <img width={150} src={label.label} alt=""/>
                    </div>
                  </div>
                </div>
              </Card>
            </div>
          }
        </div>

        <div className="row">
          {upsErrors && upsErrors.map((error, index) => (
            <div className="top_notification width_full mb_10">
              <div className="left width_full">
                <div className="icon" style={{width: '40px', height: "40px"}}>
                  <img src="/assets/media/images/header_icon1.png" alt=""/>
                </div>
                <div className="text d_flex_btwn width_full ">
                  <h2>{error} </h2>
                  <Button text={"X"} onClickHandler={() => removeError(index)}
                          classes={"btn_sm has_border_radius_15 btn_warning"} variant={'warning'}/>
                </div>
              </div>
            </div>
          ))}
        </div>
        {!label &&
        <div className="row mb_30">
          <div className="col_12">
            <div className="d_flex_end">
              <Button
                disabled={isPending ? true : false}
                text="Make Shipment"
                type={"submit"}
                classes={'btn btn_black pt_10 pb_10 pl_30 pr_30 font_16p'}
              />
            </div>
          </div>
        </div>
        }
      </form>
    }
    </>
  );
}

export default UPSShipping;
