import {getCommonSettings} from "../../../api/dashboard";
import {useLoaderData} from "react-router-dom";
import {useEffect, useState} from "react";
import Card from "../../../components/UI/Card";
import UpsSettingsComponent from "../../../views/settings/common-settings/UpsSettingsComponent";
import {getStates} from "../../../api/commonRequest";

export async function LoadSettings(){
  const [ settings, states] = await Promise.all([
    getCommonSettings(),
    getStates()
  ]);

  return { settings, states};
}

const CommonSettings = () => {
  const [settings, setSettings] = useState(null);
  const [states, setStates] = useState([]);
  const loaderData = useLoaderData();
  // if(loaderData && !settings){
  //   setSettings(loaderData.settings);
  // }

  useEffect(()=>{
    if(loaderData && !settings){
      setSettings(loaderData.settings);
      setStates(loaderData.states);
    }
  }, [loaderData.settings])

  return (
    <>
      <Card title={"Customer Service Information"}>
        {settings &&
          <UpsSettingsComponent settings={settings} states={states}/>
        }
      </Card>
    </>
  )
}

export default CommonSettings;