import Input from "../../components/UI/form/Input";
import Select from "../../components/UI/form/Select";
import {useMutation, useQuery, useQueryClient} from "@tanstack/react-query";
import {getCountries, getAllCategories, getStates} from "../../api/commonRequest";
import {storeVendors, updateVendor} from "../../api/vendor";
import {toast} from "react-toastify";
import {useNavigate, useParams} from 'react-router-dom';
import SubHeadingComponent from "../../components/UI/SubHeadingComponent";
import {setValidationMessage} from "../../util/validationMessage";
import {useEffect, useState} from "react";
import Button from "../../components/UI/Button";
import RadioBox from "../../components/UI/form/RadioBox";
import Checkbox from "../../components/UI/form/Checkbox";
import MultiSelect from "../../components/UI/form/MultiSelect";


const VendorFormComponent = ({watch, setError, setValue, handleSubmit, errors, register, getValues, reset}) => {
  const navigate = useNavigate();
  const params = useParams();
  const [currentStates, setCurrentStates] = useState([])

  const queryClient = useQueryClient();

  const {data: countries} = useQuery({
    queryKey:['countries'],
    queryFn: getCountries
  })

  const {data: states} = useQuery({
    queryKey:['states'],
    queryFn: getStates
  })


  const {data: categories} = useQuery({
    queryKey:['all_categories'],
    queryFn: getAllCategories
  })

  useEffect(() => {
    if(states && getValues('country_id')) {
      let tempStates = states.filter((item) => item.country_id === Number(getValues('country_id')))
      setCurrentStates(tempStates)

      if(!tempStates.length){
        setValue('state', '')
      }
    }
  }, [watch('country_id')])


  const [toastId, setToastId] = useState(null);

  const {isPending, mutate} = useMutation({
    mutationFn: params.id ? (data) => updateVendor(params.id, data) : storeVendors,
    onMutate: () => {
      setToastId(toast.loading("Please wait..."))
    },
    onSuccess: (res) => {
      toast.update(toastId, { render: `Vendor ${params.id ? 'Updated' : 'Added'}`, type: "success", isLoading: false, autoClose: true });
      queryClient.invalidateQueries({
        predicate: (query) => query.queryKey[0] === 'vendors',
      });
    },
    onError: (error) => {
      let errorMsg = "Something happened!";
      if (error && error.message && error.message.errors) {
        setValidationMessage(error.message.errors, setError);
        errorMsg = error.message.message;
      }

      toast.update(toastId, { render: errorMsg, type: "error", isLoading: false, autoClose: true });
    }
  });

  const onSubmit = (data) => {
    let formData = new FormData()
    Object.keys(data).forEach(key => {
      formData.append(key, data[key] ? data[key] : '');
    })

    if(data.logo){
      const file = data.logo[0];
      if(file)
        formData.append('logo', file)
    }

    if(data.banner){
      const banner = data.banner[0];

      if(banner)
        formData.append('banner', banner)
    }

    mutate(formData)
  }

  return (
    <form onSubmit={handleSubmit(onSubmit)}>
      <SubHeadingComponent heading={`Vendor Information`} classes={'mt_0 pb_10 mb_30'} />

      <div className="row">
        <div className="col_4 pr_20">
          <Input label="Name"
                 inline={true}
                 name="name"
                 errors={errors}
                 validationRules={{required: true, type: 'string'}}
                 register={register}/>
        </div>

        <div className="col_4 pr_20">
          <Input label="Email"
                 inline={true}
                 name="email"
                 type={'email'}
                 errors={errors}
                 validationRules={{required: true, type: 'email'}}
                 register={register}/>
        </div>
        <div className="col_4">
          <Input label="Phone"
                 inline={true}
                 name="phone"
                 errors={errors}
                 validationRules={{required: true, type: 'string'}}
                 register={register}/>
        </div>

        <div className="col_4 pr_20">
          <Input label="Business Name"
                 inline={true}
                 name="legal_business_name"
                 errors={errors}
                 validationRules={{required: true, type: 'string'}}
                 register={register}/>
        </div>
        <div className="col_4 pr_20">
          <Select
            label="Country"
            inline={true}
            name="country_id"
            options={countries}
            errors={errors}
            optionValue={'id'}
            optionLabel={'name'}
            validationRules={{required: true}}
            register={register}/>
        </div>
        <div className="col_4 pr_20">
          {currentStates && currentStates.length > 0 ?
          <Select
            label="State"
            inline={true}
            name="state"
            options={currentStates}
            errors={errors}
            optionValue={'code'}
            optionLabel={'name'}
            validationRules={{required: true}}
            register={register}/>
            :
            <span>No State</span>
          }
        </div>
        <div className="col_4">
          <Input label="Address"
                 inline={true}
                 name="address"
                 errors={errors}
                 validationRules={{required: true, type: 'string'}}
                 register={register}/>
        </div>

        <div className="col_4 pr_20">
          <Input label="City"
                 inline={true}
                 name="city"
                 errors={errors}
                 validationRules={{required: true, type: 'string'}}
                 register={register}/>
        </div>

        <div className="col_4 pr_20">
          <Input label="Post Code"
                 inline={true}
                 name="postal_code"
                 errors={errors}
                 validationRules={{required: true, type: 'string'}}
                 register={register}/>
        </div>

        <div className="col_4">
          <Input label="Commission"
                 inline={true}
                 name="commission"
                 errors={errors}
                 type={'number'}
                 validationRules={{required: true, type: 'float'}}
                 register={register}/>
        </div>

        <div className="col_4 pr_20">
          <Input label="Bio"
                 inline={true}
                 name="bio"
                 errors={errors}
                 register={register}/>
        </div>
        <div className="col_4 pr_20">
          <Input label="Shipping From"
                 inline={true}
                 name="shipping_from"
                 errors={errors}
                 register={register}/>
        </div>
        <div className="col_4">
          <Input label="Shipping Time"
                 inline={true}
                 name="shipping_time"
                 errors={errors}
                 register={register}/>
        </div>
        <div className="col_4 pr_20">
          <Input label="Logo"
                 inline={true}
                 name="logo"
                 errors={errors}
                 type={"file"}
                 message={'Best size (300px X 60px)'}
                 register={register}/>

        </div>
        <div className="col_4 pr_20">
          <Input label="Banner"
                 inline={true}
                 name="banner"
                 errors={errors}
                 message={'Best size (1900px X 400px)'}
                 type={"file"}
                 register={register}/>
        </div>
        <div className="col_4">
          <MultiSelect
              label="Categories"
              inline={true}
              optionLabel={'name'}
              options={categories || []}
              name="categories"
              setValue={setValue}
              defaultValues={getValues('categories') ? getValues('categories').map(c => c) : []}
              errors={errors}
              validationRules={{required: true}}
              register={register}
          />
        </div>
        <div className="col_12">
          <div className="form_row_inline mb_0">
            <div className="form_input width_full">
              <div className="d_flex_btwn width_full">
                <RadioBox label="Status"
                          inline={true}
                          name="status"
                          errors={errors}
                          options={[{id: "1", name: 'Active'}, {id: "0", name:"Inactive"}]}
                          validationRules={{required:true, type: 'string'}}
                          register={register}/>

                <div className="d_flex_end ">
                  <Checkbox name={`block`}
                            label={"Suspend Vendor"}
                            register={register}
                            checked={getValues('block') ? 1 : 0} />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      {(!params.id) &&
      <>
        <SubHeadingComponent heading={`Admin User Information`} classes={'mt_0 pb_10 pt_30 mb_30'} />
        <div className="row">
          <div className="col_6 pr_20">
            <Input label="Name"
                   name="user_name"
                   errors={errors}
                   validationRules={{required: true, type: 'string'}}
                   register={register}/>
          </div>

          <div className="col_6">
            <Input label="Email"
                   name="user_email"
                   type={'email'}
                   errors={errors}
                   validationRules={{required: true, type: 'email'}}
                   register={register}/>
          </div>

          <div className="col_6 pr_20">
            <Input label="Password"
                   name="user_password"
                   type={'password'}
                   errors={errors}
                   validationRules={{required: true}}
                   register={register}/>
          </div>
          <div className="col_6">
            <Input label="Confirm Password"
                   name="user_confirm_password"
                   type={'password'}
                   errors={errors}
                   validationRules={{required: true}}
                   register={register}/>
          </div>

        </div>
      </>
      }



        <div className="d_flex_end">
          <Button
            isPending={isPending}
            text="SAVE"
            type="submit"
          />
        </div>

    </form>
  );
};

export default VendorFormComponent;