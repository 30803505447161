import React, {useState} from "react";
import {useForm} from "react-hook-form";
import {useMutation} from "@tanstack/react-query";
import {toast} from "react-toastify";
import {setValidationMessage} from "../../../util/validationMessage";
import {addNewPermission} from "../../../api/dashboard";
import Card from "../../../components/UI/Card";
import Input from "../../../components/UI/form/Input";
import Button from "../../../components/UI/Button";

const AddNewPermission = () => {
  const [toastId, setToastId] = useState(null);
  const {setValue,  control, register, setError, getValues,  watch, handleSubmit, formState: { errors }} = useForm()

  const {isPending, mutate} = useMutation({
    mutationFn:  (data)=>addNewPermission(  data),
    onMutate: () => {
      setToastId(toast.loading("Please wait..."))
    },
    onSuccess: (res) => {
        toast.update(toastId, { render: `Permission has been Updated'}`, type: "success", isLoading: false, autoClose: true });
    },
    onError: (error) => {
      let errorMsg = "Something happened!";
      if (error && error.message && error.message.errors) {
        setValidationMessage(error.message.errors, setError);
        errorMsg = error.message.message;
      }
      toast.update(toastId, { render: errorMsg, type: "error", isLoading: false, autoClose: true });
    }

  });

  const submitHandler = (data) => {
    data.permission = data.permission.toUpperCase();
     mutate(data);
  };
  return (
    <>
      <form onSubmit={handleSubmit(submitHandler)}>
        <div className="row">
          <div className="col_12">
            <h1 className="common_title">{'Add New Permission'}</h1>
          </div>
        </div>
        <div className="row">
          <div className="col_12">
            <Card>
              <div className="row">
                <div className="col_6">
                  <Input label="Permission Code"
                         name="permission"
                         inline={true}
                         classes={"mb_5"}
                         errors={errors}
                         validationRules={{required: true, type: 'string'}}
                         register={register}/>
                </div>
                <div className="col_6">
                      <div className="d_flex_start">
                        <Button
                          disabled={isPending ? true : false}
                          text="Save"
                          type={"submit"}
                          classes={'btn btn_black pt_10 pb_10 pl_30 pr_30 font_16p'}
                        />
                      </div>
                </div>
              </div>
            </Card>
          </div>
        </div>
      </form>
    </>
  )
}

export default AddNewPermission;