import {useForm} from "react-hook-form";
import {useState} from "react";
import {useMutation} from "@tanstack/react-query";
import {toast} from "react-toastify";
import {setValidationMessage} from "../../../util/validationMessage";
import {updateCommonSettings} from "../../../api/dashboard";
import Input from "../../../components/UI/form/Input";
import Button from "../../../components/UI/Button";
import Select from "../../../components/UI/form/Select";

const UpsSettingsComponent = ({settings, states}) => {

  const {setValue, getValues, register, setError, handleSubmit, formState: { errors }} = useForm(
    {
      defaultValues:{
        columns: ['ups_client_id', 'ups_client_secret', 'shipping_from_name' ,'shipping_from_number', 'shipping_from_city', 'shipping_from_address','shipping_from_postal_code','shipping_from_state_code','shipping_from_country_code'],
        ups_client_id: settings ? settings.ups_client_id : '',
        ups_client_secret: settings ? settings.ups_client_secret : '',
        ups_refresh_token: settings ? settings.ups_refresh_token : '',
        ups_access_token: settings ? settings.ups_access_token : '',
        shipping_from_name: settings ? settings.shipping_from_name : '',
        shipping_from_number: settings ? settings.shipping_from_number : '',
        shipping_from_address: settings ? settings.shipping_from_address : '',
        shipping_from_city: settings ? settings.shipping_from_city : '',
        shipping_from_postal_code: settings ? settings.shipping_from_postal_code : '',
        shipping_from_state_code: settings ? settings.shipping_from_state_code : '',
        shipping_from_country_code:  'US',
      }
    }
  );
  const [toastId, setToastId] = useState(null);

  const {isPending, mutate} = useMutation({
    mutationFn: updateCommonSettings,
    onMutate: () => {
      setToastId(toast.loading("Please wait..."))
    },
    onSuccess: (res) => {
      toast.update(toastId, { render: `Information updated`, type: "success", isLoading: false, autoClose: true });
      // reset();
    },
    onError: (error) => {
      let errorMsg = "Something happened!";
      if (error && error.message && error.message.errors) {
        setValidationMessage(error.message.errors, setError);
        errorMsg = error.message.message;
      }

      toast.update(toastId, { render: errorMsg, type: "error", isLoading: false, autoClose: true });
    }
  });

  const onSubmit = (data) => {
    mutate(data)
  }
  return (
    <>
      <form onSubmit={handleSubmit(onSubmit)}>
        <div className="row">
          <div className="col_6">
            <Input label="Shipping from name"
                   name="shipping_from_name"
                   inline={true}
                   errors={errors}
                   validationRules={{required: true}}
                   register={register}/>
          </div>
          <div className="col_6">
            <Input label="Phone"
                   name="shipping_from_number"
                   inline={true}
                   errors={errors}
                   validationRules={{required: true}}
                   register={register}/>
          </div>
          <div className="col_6">
            <Input label="Address"
                   name="shipping_from_address"
                   inline={true}
                   errors={errors}
                   validationRules={{required: true}}
                   register={register}/>
          </div>
          <div className="col_6">
            <Input label="City"
                   name="shipping_from_city"
                   inline={true}
                   errors={errors}
                   validationRules={{required: true}}
                   register={register}/>
          </div>
          <div className="col_6">
            <Input label="Post Code"
                   name="shipping_from_postal_code"
                   inline={true}
                   errors={errors}
                   validationRules={{required: true}}
                   register={register}/>
          </div>
          <div className="col_6">
            <Select
              name={`shipping_from_state_code`}
              options={states}
              label={"State"}
              inline={true}
              optionValue={'code'}
              errors={errors}
              validationRules={{required: true}}
              register={register}/>
          </div>
          <div className="col_6">
            <Input label="Country Code"
                   name="shipping_from_country_code"
                   inline={true}
                   errors={errors}
                   disable={true}
                   validationRules={{required: true}}
                   register={register}/>
          </div>

          <div className="col_6">
            <Input label="UPS client id"
                   name="ups_client_id"
                   inline={true}
                   disable={true}
                   errors={errors}
                   validationRules={[]}
                   register={register}/>
          </div>
          <div className="col_6">
            <Input label="UPS client secret"
                   name="ups_client_secret"
                   inline={true}
                   errors={errors}
                   disable={true}
                   validationRules={[]}
                   register={register}/>
          </div>
          <div className="col_6">
            <Input label="UPS refresh token"
                   name="ups_refresh_token"
                   inline={true}
                   errors={errors}
                   disable={true}
                   validationRules={[]}
                   register={register}/>
          </div>
          <div className="col_6">
            <Input label="UPS Access Token"
                   name="ups_access_token"
                   inline={true}
                   errors={errors}
                   disable={true}
                   validationRules={[]}
                   register={register}/>
          </div>
        </div>
        <div className="row">
          <div className="col_12">
            <div className="d_flex_end">
              <Button
                text="Update UPS Information"
                isPending={isPending}
                type={"submit"}
              />
            </div>
          </div>
        </div>
      </form>
    </>
  )
}

export default UpsSettingsComponent