import API from "../util/api";

export const dashboardData = async () => {
  let data = null;

  await API.get('/dashboard')
    .then(res => {

      data = res.data;
    })
    .catch((err) => {
      throw new Error(JSON.stringify(err.response.data))
    });

  return data;
}

export const bestSaleItems = async () => {
  let data = null;

  await API.get('/best-sale-items')
      .then(res => {

        data = res.data;
      })
      .catch((err) => {
        throw new Error(JSON.stringify(err.response.data))
      });

  return data;
}

export const latestOrders = async () => {
  let res = null;

  await API.get('/latest-orders')
      .then(({data}) => {
        res = data.data;
      })
      .catch((err) => {
        throw new Error(JSON.stringify(err.response.data))
      });

  return res;
}

export const getCommonSettings = async () => {
  let res = null;

  await API.get('/common-settings')
    .then(({data}) => {
      res = data.data;
    })
    .catch((err) => {
      throw new Error(JSON.stringify(err.response.data))
    });

  return res;
}

export const updateCommonSettings = async ( payload) => {
  let data = null;
  await API.post(`/common-settings`, payload)
    .then(res => {
      data = res.data.data;
    })
    .catch((err) => {
      throw new Error(JSON.stringify(err.response.data))
    });

  return data;
}

export const addNewPermission = async ( payload) => {
  let data = null;
  await API.post(`/add-new-permission`, payload)
    .then(res => {
      data = res.data.data;
    })
    .catch((err) => {
      throw new Error(JSON.stringify(err.response.data))
    });

  return data;
}